import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)
const Users = () =>
    import ('../views/Users/Users.vue')
const Infosociete = () =>
    import ('../views/Infosociete/Infosociete.vue')
const Tresoreries = () =>
    import ('../views/Tresoreries/Tresoreries.vue')
const Exercices = () =>
    import ('../views/Exercices/Exercices.vue')
const TresoreriesMouvement = () =>
    import ('../views/TresoreriesMouvement/TresoreriesMouvement.vue')
const TresoreriesFlux = () =>
    import ('../views/TresoreriesFlux/TresoreriesFlux.vue')
const Depenses = () =>
    import ('../views/Depenses/Depenses.vue')
const Typesdepense = () =>
    import ('../views/Typesdepense/Typesdepense.vue')
const Activites = () =>
    import ('../views/Activites/Activites.vue')
const EtatFinancement = () =>
    import ('../views/EtatFinancement/EtatFinancement.vue')
const ChiffreAffaire = () =>
    import ('../views/ChiffreAffaire/ChiffreAffaire.vue')
const Dossiers = () =>
    import ('../views/Dossiers/Dossiers.vue')

const CompagnieListe = () =>
    import ('../views/CompagnieListe/CompagnieListe.vue')

const ArticleListe = () =>
    import ('../views/ArticleListe/ArticleListe.vue')

const EcotermeListe = () =>
    import ('../views/EcotermeListe/EcotermeListe.vue')

const ClientListe = () =>
    import ('../views/ClientListe/ClientListe.vue')

const DossierListe = () =>
    import ('../views/DossierListe/DossierListe.vue')

const DossierSuivi = () =>
    import ('../views/DossierSuivi/DossierSuivi.vue')

const TauxechangeListe = () =>
    import ('../views/TauxechangeListe/TauxechangeListe.vue')

const DossierCaution = () =>
    import ('../views/DossierCaution/DossierCaution.vue')

const FacturePreformaList = () =>
    import ('../views/FacturePreformaList/FacturePreformaList.vue')

const FacturePreforma = () =>
    import ('../views/FacturePreforma/FacturePreforma.vue')
const TableauBord = () =>
    import ('../views/TableauBord/TableauBord.vue')


const routes = [
    {
        path: '/',
        name: 'Dashboard',
        component: TableauBord
    },
    {
        path: '/about',
        name: 'About',
        // route level code-splitting
        // this generates a separate chunk (about.[hash].js) for this route
        // which is lazy-loaded when the route is visited.
        component: () =>
            import ( /* webpackChunkName: "about" */ '../views/About.vue')
    },
    {
        path: '/utilisateurs',
        name: 'Users',
        component: Users

    },
    {
        path: '/infosociete',
        name: 'Infosociete',
        component: Infosociete

    },
    {
        path: '/tresoreries',
        name: 'Tresoreries',
        component: Tresoreries
    },
    {
        path: '/exercices',
        name: 'Exercices',
        component: Exercices
    },
    {
        path: '/tresoreriesmouvement',
        name: 'TresoreriesMouvement',
        component: TresoreriesMouvement
    },
    {
        path: '/tresoreriesmouvement:tresoreries_id',
        name: 'TresoreriesMouvementparid',
        component: TresoreriesMouvement
    },
    {
        path: '/tresoreriesflux',
        name: 'TresoreriesFlux',
        component: TresoreriesFlux
    },
    {
        path: '/tresoreriesflux:tresoreries_id',
        name: 'TresoreriesFluxparid',
        component: TresoreriesFlux
    },
    {
        path: '/nouveaudossier',
        name: 'Nouveaudossier',
        component: Dossiers
    },
    {
        path: '/caution',
        name: 'DossierCaution',
        component: DossierCaution
    },
    {
        path: '/depensesvehicule',
        name: 'Depenses',
        component: Depenses
    },
    {
        path: '/typesdepense',
        name: 'Typesdepense',
        component: Typesdepense
    },
    {
        path: '/activites',
        name: 'activites',
        component: Activites
    },
    {
        path: '/etatfinancement',
        name: 'EtatFinancement',
        component: EtatFinancement
    },
    {
        path: '/ca',
        name: 'ChiffreAffaire',
        component: ChiffreAffaire
    },
    {
        path: '/compagnie/liste',
        name: 'CompagnieListe',
        component: CompagnieListe
    },
    {
        path: '/article/liste',
        name: 'ArticleListe',
        component: ArticleListe
    },
    {
        path: '/incoterm/liste',
        name: 'EcotermeListe',
        component: EcotermeListe
    },
    {
        path: '/client/liste',
        name: 'ClientListe',
        component: ClientListe
    },
    {
        path: '/dossier/liste',
        name: 'DossierListe',
        component: DossierListe
    },
    {
        path: '/dossier/edit/:id_dossier',
        name: 'Nouveaudossier',
        component: Dossiers
    },
    {
        path: '/dossier/suivi/',
        name: 'DossierSuivi',
        component: DossierSuivi
    },
    {
        path: '/dossier/suivi/:id_dossier',
        name: 'DossierSuivi',
        component: DossierSuivi
    },
    {
        path: '/tauxechange',
        name: 'TauxechangeListe',
        component: TauxechangeListe
    },
    {
        path: '/facturepreforma/liste',
        name: 'FacturePreformaList',
        component: FacturePreformaList
    },
    {
        path: '/facturepreforma',
        name: 'FacturePreforma',
        component: FacturePreforma
    },
    {
        path: '/facturepreforma/:id_facture_preforma',
        name: 'FacturePreforma',
        component: FacturePreforma
    },
    {
        path: '/dashboard',
        name: 'TableauBord',
        component: TableauBord
    },
]

const router = new VueRouter({
    mode: 'hash',
    base: process.env.BASE_URL,
    routes
})

export default router
